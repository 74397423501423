/* Header */
.header {
  font: normal 18px Arial, Helvetica, sans-serif;
  width: fit-content;
  max-width: 400px;
  margin: 10px;
  line-height: 26px;
}

.language-select {
  position: absolute;
  margin: 10px;
  top: 0;
  right: 0;
}

/* Main form */
.main-pattern-instruction {
  font: normal 18px Arial, Helvetica, sans-serif;
  margin: auto;
  margin-bottom: 12px;
  margin-top: 10em;
  width: 60%;
}

.pattern-list {
  margin: auto;
  width: 60%;
}

.main-button {
  margin: auto;
  width: 60%;
}

.pattern-file-input {
  width: 400px;
  margin: 15px 0 15px 0;
}

/* Table */
.table-wrapper {
  width: 80%;
  margin: auto;
  margin-top: 12em;
}

.content-file-input {
  width: 150px;
}

/* Footer */
.footer {
  font: normal 18px Arial, Helvetica, sans-serif;
  width: fit-content;
  line-height: 26px;
  margin: auto;
  margin-top: 10em;
  width: 80%;
}

.progress {
  width: 90%;
  margin: 26px 5% 26px 5%;
}

.final-output {
  font: normal 18px Arial, Helvetica, sans-serif;
  margin: auto;
  margin-top: 5em;
  width: 80%;
}
